import { ExternalUnit } from '@chirp/engrain-client';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { MapContext } from '../EngrainMap';
import styles from './styles';

interface UnitDisplayProps {
  onUnitChange: (option: any) => void;
  units: ExternalUnit[];
  willHighlightUnit: boolean;
}

const UnitDisplay: React.FC<UnitDisplayProps> = (props) => {
  const context = useContext(MapContext);
  const { map } = context;
  const { willHighlightUnit, units } = props;

  const UnitBar = useRef<any>();
  const [selectedId, setSelectedId] = useState('');


  useEffect(() => {
    UnitBar.current && UnitBar.current.scrollIntoView(0, 0);

    // if (units && units.length) {
    //   setSelectedId(units[0].unitId);
    // }
  }, [units]);

  // useEffect(() => {
  //   if (map && !!selectedId) {
  //     (map as any).panTo((map as any).units().find(selectedId).center());
  //     (map as any).zoomTo((map as any).units().find(selectedId).center(), 1);
  //   }
  // }, [map, selectedId])

  if (!map) return null;

  const handleUnitOnClick = (_: React.MouseEvent<HTMLDivElement>, unit: ExternalUnit) => {
    // e.currentTarget.scrollTo = 500;
    setSelectedId(unit.unitId);

    props.onUnitChange(unit);

    // const flavoursContainer: any = document.getElementById("units-part");
    // flavoursContainer.scrollTo(flavoursContainer.scrollLeft + 50 * index, 0);

    (map as any).panTo((map as any).units().find(unit.unitId).center());
    (map as any).zoomTo((map as any).units().find(unit.unitId).center(), 1);
  };

  if (!units) {
    return null;
  }

  return (
    <div ref={UnitBar} id="units-part" className={styles.unitsPart}>
      {units.map((unit: ExternalUnit) =>
        (<div
          key={unit.unitId}
          id={unit.unitId}
          className={`${(unit.unitId === selectedId && willHighlightUnit) ? styles.selected : styles.notSelected} ${styles.buttonsUnits}`}
          onClick={(e) => handleUnitOnClick(e, unit)}
        >
          <span className={styles.unitText}>{unit.name}</span>
        </div>)
      )}
    </div>
  );
};

export default UnitDisplay;
