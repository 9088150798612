import React from 'react';
import Loader from '../../assets/loader.png';
import styles from './styles';

const LoaderPro = () => {
  return (
    <div className={styles.Loading}>
      <div className={styles.loadingImg}>
        <img alt="" style={{ width: "100%", height: "100%" }} src={Loader} />
      </div>
    </div>
  );
};

export default LoaderPro;
