import React from 'react';

import styles from './styles';

interface ButtonIconProps {
  className: string;
  icon: string;
  onClick: () => void;
  iconDimensions: {
    x: string;
    y: string;
  };
}

const ButtonIcon = ({
  className,
  icon,
  onClick,
  iconDimensions,
}: ButtonIconProps) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={`${styles.buttonIcon} ${className} buttonIcon`}
    >
      <img
        alt=""
        src={icon}
        style={{ width: iconDimensions.x, height: iconDimensions.y }}
      ></img>
    </div>
  );
};

export default ButtonIcon;
