import { stylesheet } from 'typestyle';

const styles = stylesheet({
  buttonsUnits: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'static',
    minWidth: '100px',
    minHeight: '50px',
    left: '0px',
    bottom: '0px',
    background: '#ffffff',
    boxSizing: 'border-box',
    borderRadius: '70px',
    fontSize: '16px'
  },
  selected: {
    backgroundColor: '#0076cc',
    color: 'white',
    border: '1px solid #c7d6e1'
  },
  notSelected: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #c7d6e1'
  },
  unitText: {
    left: '33px',
    top: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px'
  },
  unitsPart: {
    display: 'flex',
    gap: '16px',
    overflowX: 'scroll'
  },
});

export default styles;
