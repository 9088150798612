import { ExternalFloor, ExternalUnit } from '@chirp/engrain-client';

export interface Option {
  value: string;
  label: string;
}

export interface AccessPointWithLocation {
  accessPointId: string;
  location: number[];
  name: string;
  externalFloorId: string;
}

export enum EngrainMapViewMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export interface MarkerIcon {
  url: string;
  size: number[];
  anchor: number[];
}

export enum GeoLocationPermissionStatus {
  GRANTED = 'GRANTED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  POSITION_UNAVAILABLE = 'POSITION_UNAVAILABLE',
  TIMEOUT = 'TIMEOUT',
  UNKNOWN = 'UNKNOWN',
}

export interface BookedUnit {
  unitId: string;
  pinCode?: string | null;
}

export interface EngrainMapProps {
  accessPoints: AccessPointWithLocation[];
  apiKey: string;
  bookedUnitIds?: string[]; //deprecated
  bookedUnits: BookedUnit[];
  floorsData: ExternalFloor[];
  hideLocationButton?: boolean;
  mapId: string;
  markerIcon?: MarkerIcon;
  promptTutorial?: boolean;
  propertyName?: string;
  selectedUnitColor: string;
  unitColor: string;
  unitsData: ExternalUnit[];
  viewMode: EngrainMapViewMode;
  zoomToLocation?: AccessPointWithLocation | null;
  geoLocationPermissionStatus?: GeoLocationPermissionStatus | null;
  onApprovedLocationStatus?: (status: GeoLocationPermissionStatus) => void;
  onFinishedTutorial?: () => void;
  onError?: (error: string) => void;
  onMapRightClick?: (locations: PointLocation) => void;
  onMarkerClick?: (marker: Marker) => void;
  onMarkerMouseEnter?: (marker: MarkerEvent) => void;
  onMarkerMouseOut?: (marker: MarkerEvent) => void;
  toggleBackButton?: () => void;
  toggleQrScanner?: () => void;
}

export interface Marker {
  id: string;
  translate: [number, number];
  cursor: string;
  draggable: boolean;
  icon: MarkerIcon;
  remove: () => void;
}

export interface MarkerEvent {
  marker: any;
  originalEvent: PointerEvent;
}

export interface UnitEvent {
  unit: any;
  originalEvent: PointerEvent;
}

export interface PointLocation {
  point: number[];
  viewPort: number[];
  floorId: string;
}
