import { useContext, useEffect } from 'react';

import { MapContext } from '../EngrainMap';

export interface UnitProps {
  color?: string;
  pinCode: string | null | undefined;
  selectedColor: string;
  unitId: string;
}

const Unit = (props: UnitProps) => {
  const context = useContext(MapContext);
  const { units } = context;
  const { color, selectedColor, unitId, pinCode } = props;

  const cleanup = (mapElement: HTMLElement | null) => {
    const childElement = document.getElementById('pincode-popup');

    if (childElement) {
      mapElement?.removeChild(childElement);
    }
  }

  useEffect(() => {
    (units as any).color(color);
    const mapElement = document.getElementById('map');
    cleanup(mapElement);

    if (unitId && selectedColor) {
      const unit = (units as any).find(unitId);
      const unitData = unit.data();
      unit.color(selectedColor);

      if (pinCode) {
      const bounds = unitData.bounds;
      const x = bounds.x;
      const y = bounds.y;
      const height = bounds.height;

        const pinCodePopup = `
        <g id="pincode-popup" transform="translate(${x}, ${y - height - 10})">
          <path d="M 10 0 Q 139.928 0.013 204.978 0.028 Q 215.055 -0.051 215 10 Q 215 41.979 215.148 70 Q 215.166 80.085 205.06 80.271 Q 127.5 80 55 80 Q 52 86.5 49 93 Q 46 86.5 43 80 Q 26.5 80 10 80 Q 0 80 0 70 Q 0 40 0 10 Q 0 0 10 0" stroke-width="2" stroke="#c7d6e1" fill="white"/>
          <path d="M 177.46 37.495 L 175.073 40.013 L 185.004 49.943 L 205.112 30.02 L 202.467 27.498 L 184.994 45.058 Z" fill="#0076CC"/>
          <text x="10" y="50" fill="#0076CC" font-size="18" font-family="Montserrat, sans-serif">Pin Code: ${pinCode}</text>
        </g>`;
        mapElement?.insertAdjacentHTML('beforeend', pinCodePopup);
      }
    }

    return () => {
      cleanup(mapElement);
      (units as any).color(color);
    }
  }, [unitId, selectedColor, units]);

  return null;
};

export default Unit;
