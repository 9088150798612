export const floorFormatted = (label: string) => {
  if (/^\d+$/.test(label)) {
    if (label.endsWith('1') && !label.includes('11')) {
      return `${label}st`;
    }

    if (label.endsWith('2') && !label.includes('12')) {
      return `${label}nd`;
    }

    if (label.endsWith('3') && !label.includes('13')) {
      return `${label}rd`;
    }

    return `${label}th`;
  } else {
    return label;
  }
};
