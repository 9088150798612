import { stylesheet, keyframes } from 'typestyle';

const pulse = keyframes({
  '0%': {
    boxShadow: '0 0 0 0 rgba(199, 214, 225, 1)',
  },

  '70%': {
    boxShadow: '0 0 0 20px rgba(199, 214, 225, 0)',
  },

  '100%': {
    boxShadow: '0 0 0 0 rgba(199, 214, 225, 0)',
  }
});


const styles = stylesheet({
  mapBody: {
    width: '100%',
    height: '100%'
  },
  mapLoader: {
    height: '100vh',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  floorPicker: {
    position: 'absolute',
    left: '5%',
    bottom: '10%'
  },
  unitDisplay: {
    maxWidth: '100%',
    minWidth: '100%',
    position: 'absolute',
    top: 0,
    left: '5%',
    paddingRight: '10%',
    overflowY: 'scroll',
  },
  locationButtonWrapper: {
    position: 'absolute',
    bottom: '10%',
    right: '5%',
  },
  ButtonIconQrCode: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  ButtonGoBackwards: {
    position: 'absolute',
    left: '5%',
    top: '5%'
  },
  map___g___g_nth_child_4____g___g___path: {
    fill: 'white'
  },
  bottomController: {
    position: 'absolute',
    bottom: '12vh',
    height: 140,
    width: '100%',
  },
  qrTooltip: {
    border: '1px solid #c7d6e1',
    borderRadius: 10,
    top: 102,
    position: 'absolute',
    width: 300,
    right: -12,
    background: 'white',
    zIndex: 1,
  },
  locationTooltip: {
    border: '1px solid #c7d6e1',
    borderRadius: 10,
    bottom: 102,
    position: 'absolute',
    width: 300,
    right: -12,
    background: 'white',
    zIndex: 1,
  },
  qrButtonWrapper: {
    position: 'absolute',
    right: '5%',
    top: '5%',
  },
  blur: {
    filter: 'blur(5px)',
  },
  pulsing: {
    borderRadius: '50%',
    animationName: pulse,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    pointerEvents: 'none',
  },
  layer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
    opacity: 0.6,
    zIndex: 1,
  },
  zIndex5: {
    zIndex: 5,
  }
});

export default styles;
