import React from 'react';
import Select, { components, StylesConfig } from 'react-select';

import stair from '../../assets/stairs.svg';
import { floorFormatted } from '../../helpers/floorFormatter';
import { Option } from '../../interfaces';
import styles from './styles';

interface PropertyFloorPickerProps {
  floors: Option[];
  defaultValue: Option;
  onFloorChange: (id: string) => void;
}

const IndividualOption = (props: any) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className={styles.floorNameDynamic}>
        {floorFormatted(props.data.label)}
      </div>
      <div className={styles.floorNameStatic}>Floor</div>
      {props.data.denoteFloorWithBookedUnits && <span className={styles.dot}></span>}
    </div>
  );
};

const FormatOptionLabel = (props: any) => {
  return (
    <div className={styles.formatOptionLabel}>
      <div className={styles.stairsAndFloor}>
        <div className={styles.stairsUnion}>
          <img style={{ padding: '10px' }} src={stair} alt='Floor Selection' />
        </div>
        <div className={styles.selectFloor}>Select Floor</div>
      </div>
      <div className={styles.floorNameFormatted}>
        {floorFormatted(props.data.label)}
      </div>
    </div>
  );
};

const FloorPicker: React.FC<PropertyFloorPickerProps> = (props) => {
  const { defaultValue, floors, onFloorChange } = props;

  const colorStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '50px',
      height: '60px',
      width: '266px',
    }),
    option: (styles, state) => {
      return {
        ...styles,
        color: '#252b2e',
        width: '266px',
        backgroundColor: state.isSelected ? '#E5F1FA' : 'white',
      };
    },

    menu: (provided) => ({
      ...provided,
      borderRadius: '15px',
      overflow: 'hidden',
    }),
    menuList: (base) => ({
      ...base,

      '::-webkit-scrollbar': {
        width: '4px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
  };

  return (
    <Select
      value={defaultValue}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: ({ children, ...props }) => {
          return (
            <components.Option {...props}>
              <IndividualOption {...props} />
            </components.Option>
          );
        },
        SingleValue: ({ children, ...props }) => {
          return (
            <components.SingleValue {...props}>
              <FormatOptionLabel {...props} />
            </components.SingleValue>
          );
        },
      }}

      menuPlacement='top'
      options={floors}
      styles={colorStyles}
      onChange={(option: any) => onFloorChange && onFloorChange(option.value)}
      isSearchable={false}
    />
  );
};

export default FloorPicker;
