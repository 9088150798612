import { stylesheet } from 'typestyle';

const styles = stylesheet({
  formatOptionLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
    color: 'black',
    alignItems: 'center'
  },
  stairsAndFloor: {
    display: 'flex',
    alignItems: 'center'
  },
  selectFloor: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#202737'
  },
  floorNameFormatted: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '33px',
    color: '#0076cc'
  },
  floorNameStatic: {
    position: 'absolute',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#202737',
    marginLeft: '75px',
    marginTop: '5px',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  floorNameDynamic: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#0076cc',
    marginLeft: '24px',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  dot: {
    position: 'absolute',
    height: '10px',
    width: '10px',
    backgroundColor: '#0076cc',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '205px',
    marginTop: '25px'
  },
  stairsUnion: {
    display: 'flex',
  },
});

export default styles;
