import { stylesheet } from 'typestyle';

const styles = stylesheet({
  popupContain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 24,
    background: 'white',
    borderRadius: '10px',
  },
  popupTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 22,
    lineHeight: '34px',
    color: '#0076cc'
  },
  popupContent: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#202737',
    alignSelf: 'stretch',
    flexGrow: 0,
    marginTop: 16,
  },
  popupButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 24px',
    background: '#fff',
    border: '1px solid #c7d6e1',
    boxShadow: '0px 12px 24px rgba(32, 39, 55, 0.1)',
    borderRadius: '70px',
    alignSelf: 'stretch',
    fontWeight: 600,
    marginTop: 16,
  },
  blueBold: {
    color: '#0076cc',
    fontWeight: 600,
  },
  welcomeImage: {
    margin: '16px 0px',
    width: '100%',
    height: 'auto'
  },
  backdropPopUp: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
  },
  popupModal: {
    position: 'absolute',
    maxWidth: 320,
    height: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    top: 0,
    bottom: 0,
    zIndex: 10,
  }
});

export default styles;
