import React, { useContext } from 'react';

import { MapContext } from '../EngrainMap';

interface FloorProps {
  floorId: string;
}

const Floor: React.FC<FloorProps> = (props) => {
  const context = useContext(MapContext);
  if (!context.levels) return null;

  let { levels } = context;
  let selection = (levels as any).has({ floor: true });
  selection.where({ floor: props.floorId }).show();
  selection.except({ floor: props.floorId }).hide();

  return null;
};

export default Floor;
