import React from 'react';
import { browserName, isIOS, isAndroid } from 'react-device-detect';
import styles from './styles';
import WelcomeModalImage from '../../assets/welcome-modal-image.png';
import classNames from 'classnames';

interface PromptProps {
  onNext: () => void;
}

const windowNavigator = navigator as any;
let browser = browserName === 'Mobile Safari' ? 'Safari' : browserName;

if (windowNavigator.brave && typeof windowNavigator.brave.isBrave == 'function') {
  browser = 'Brave';
}

const QRScannerTooltip = ({ onNext }: PromptProps) => {
  return (
    <>
      <div className={styles.popupContain}>
        <span className={styles.popupTitle}>Scan QR Codes to unlock doors</span>
        <span className={styles.popupContent}>Tap this QR scanner to unlock doors and drop your current location on the map!</span>
        <button
          onClick={() => onNext()}
          className={styles.popupButton}
          style={{backgroundColor: '#fff', color: '#0076CC'}}
        >Next</button>
      </div>
    </>
  );
};

const LocationTooltip = ({ onNext }: PromptProps) => {
  return (
    <>
      <div className={styles.popupContain}>
        <span className={styles.popupTitle}>See your live location on the map</span>
        <span className={styles.popupContent}>Tap here to allow location sharing for <span className={styles.blueBold}>{browser}</span>{` `}
        to see your live location on the property map!</span>
        <button
          onClick={() => onNext()}
          className={styles.popupButton}
          style={{backgroundColor: '#0076CC', color: '#fff'}}
        >Got it!</button>
      </div>
    </>
  );
};

const LocationServicePromp = ({ onNext }: PromptProps) => {

  return (
    <>
      <div className={styles.backdropPopUp}></div>
      <div className={classNames(styles.popupContain, styles.popupModal)}>
        <span className={styles.popupTitle} style={{ color: '#0076CC' }}>
          Allow Location Sharing
        </span>
        <span className={styles.popupContent}>Allow location sharing for your default browser <span className={styles.blueBold}>{browser}</span> to see your live location on the map.</span>
        {isIOS &&
          <span className={styles.popupContent}>{`Go to `}
            <span className={styles.blueBold}>Settings</span> &gt;{` `}
            <span className={styles.blueBold}>Privacy & Security</span> &gt;{` `}
            <span className={styles.blueBold}>Location Services</span> &gt;{` `}
            select {` `}
            <span className={styles.blueBold}>{browser}</span> and choose <span className={styles.blueBold}>While Using the App</span>
          </span>
        }
        {isAndroid &&
          <span className={styles.popupContent}>{`Go to `}
            <span className={styles.blueBold}>Settings</span> &gt;{` `}
            <span className={styles.blueBold}>Location</span> &gt;{` `}
            <span className={styles.blueBold}>App Permissions</span> &gt;{` `}
            select {` `}
            <span className={styles.blueBold}>{browser}</span> and choose <span className={styles.blueBold}>Allow</span>
          </span>
        }
        <img alt="" className={styles.welcomeImage} src={WelcomeModalImage} />
        <button
          onClick={onNext}
          className={styles.popupButton}
          style={{
            color: '#0076CC',
            backgroundColor: '#FFFFFF',
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export {
  LocationTooltip,
  LocationServicePromp,
  QRScannerTooltip,
};
