import classNames from 'classnames';
import { differenceBy, groupBy, remove, sortBy } from 'lodash';
import React, { createRef, useEffect, useState } from 'react';

import { ExternalFloor, ExternalUnit } from '@chirp/engrain-client';

import backArrow from '../../assets/back-arrow-icon.svg';
import LocationIconBlue from '../../assets/location-icon-blue.svg';
import LocationIconGrayed from '../../assets/location-icon-grayed.svg';
import QrCodeIcon from '../../assets/qr-code-icon.svg';
import { getInternalUnitId } from '../../helpers/getInternalUnitId';
import {
  AccessPointWithLocation,
  EngrainMapProps,
  EngrainMapViewMode,
  GeoLocationPermissionStatus,
  Marker,
  MarkerEvent,
  UnitEvent,
} from '../../interfaces';
import ButtonIcon from '../ButtonIcon';
import Floor from '../Floor/Floor';
import FloorPicker from '../FloorPicker';
import LoaderPro from '../Loader/Loader';
import { LocationServicePromp, LocationTooltip, QRScannerTooltip } from '../Prompt';
import Unit, { UnitProps } from '../Unit';
import UnitDisplay from '../UnitDisplay';
import styles from './styles';

export const MapContext = React.createContext({
  map: null,
  units: null,
  levels: null,
});

interface GeoLocation {
  lat: number;
  lon: number;
}

const MARKER_URL = 'https://chirp-systems.s3.us-east-2.amazonaws.com/qr-code-icon.svg';

const EngrainMap: React.FC<EngrainMapProps> = (props) => {
  const {
    accessPoints,
    apiKey,
    bookedUnits,
    floorsData,
    geoLocationPermissionStatus,
    hideLocationButton,
    markerIcon,
    onApprovedLocationStatus,
    onError,
    onFinishedTutorial,
    onMapRightClick,
    onMarkerClick,
    onMarkerMouseEnter,
    onMarkerMouseOut,
    promptTutorial,
    selectedUnitColor,
    toggleBackButton,
    toggleQrScanner,
    unitColor,
    unitsData,
    viewMode,
    zoomToLocation,
  } = props;

  const [approvedLocationPermission, setApprovedLocationPermission] = useState(false);
  const [currentAccessPoints, setAccessPoints] = useState<AccessPointWithLocation[]>([]);
  const [currentLocation, setCurrentLocation] = useState<GeoLocation | null>(null);
  const [engrainMap, setEngrainMap] = useState<any>(null);
  const [finishedTutorial, setFinishedTutorial] = useState(false);
  const [floorId, setFloorId] = useState('');
  const [floors, setFloors] = useState<ExternalFloor[]>([]);
  const [geoEnabled, setGeoEnabled] = useState(false);
  const [mapGeo, setMapGeo] = useState<any>(null);
  const [mapLocator, setMapLocator] = useState<any>(null);
  const [mapMarkers, setMapMarkers] = useState<Marker[]>([]);
  const [showGrantLocationError, setGrantshowLocationError] = useState(false);
  const [state, setState] = useState({ map: null, units: null, levels: null });
  const [tutorialStep, setTutorialStep] = useState(0);
  const [unit, setUnit] = useState<UnitProps | null>(null);
  const [units, setUnits] = useState<string[]>([]);
  const [unitsFilteredByFloor, setUnitsFilteredByFloor] = useState<ExternalUnit[]>([]);
  const [willHighlightUnit, setWillHighlightUnit] = useState(false);
  const mapRef = createRef() as React.MutableRefObject<HTMLDivElement>;

  const EngrainSdk = (window as any).unitmap;
  window.scrollTo(0, 0);

  const unitsGroupedByFloor = groupBy(unitsData, 'floorId');
  const isViewMode = viewMode === EngrainMapViewMode.VIEW;
  const enabledLocationFeature = isViewMode && !hideLocationButton && geoEnabled;

  const floorPickerOptions = floors.map((f) => {
    const unitsByFloor = unitsGroupedByFloor[f.floorId];

    return {
      value: f.floorId,
      label: f.shortLabel,
      denoteFloorWithBookedUnits:
      unitsByFloor && unitsByFloor.filter((item: ExternalUnit) => {
        const internalUnitId = getInternalUnitId(item);

        return bookedUnits?.filter(u => u.unitId === internalUnitId).length > 0;
      }).length > 0
        ? true
        : false,
    }
  });

  let unitDisplayList = unitsFilteredByFloor;

  if (isViewMode) {
    if (bookedUnits.length) {
      const bookedUnitIds = bookedUnits.map(u => u.unitId);
      unitDisplayList = unitsData.filter((u) => {
        const internalUnitId = getInternalUnitId(u);

        return internalUnitId && bookedUnitIds.includes(internalUnitId);
      });
    } else {
      unitDisplayList = [];
    }
  }

  const handleFloorChange = (floorId: string) => {
    setFloorId(floorId);
    const unitsFilteredByFloor = unitsGroupedByFloor[floorId];

    if (!!unitsFilteredByFloor && unitsFilteredByFloor.length > 0) {
      setUnitsFilteredByFloor(unitsFilteredByFloor);
      const bookedUnitOnFloor = unitsFilteredByFloor.find(u => units.includes(u.unitId));

      if (!bookedUnitOnFloor) {
        setWillHighlightUnit(false);
        setUnit(null);
      } else {
        setWillHighlightUnit(true);
      }
    }
  };

  const handleUnitChange = (unit: ExternalUnit) => {
    const [selectedUnit] = unitDisplayList.filter(u => u.unitId === unit.unitId);

    if (selectedUnit) {
      if (unit.floorId) {
        handleFloorChange(unit.floorId);
        setWillHighlightUnit(true);
      }

      const [bookedUnitPinCode] = bookedUnits.filter(u => u.unitId === getInternalUnitId(selectedUnit));

      setUnit({
        unitId: selectedUnit.unitId,
        pinCode: bookedUnitPinCode?.pinCode,
        selectedColor: selectedUnitColor,
      });
    } else {
      setUnit(null);
    }
  };

  const righClickOnMarker = (target: any) => {
    if (target && target.tagName === 'image') {
      if (markerIcon && target.getAttribute('href') === markerIcon.url) {
        return true
      }

      if (target.getAttribute('href') === MARKER_URL) {
        return true
      }
    }

    return false;
  }

  const resetMap = () => {
    setGeoEnabled(false)
    setMapLocator(null);
    setEngrainMap(null);
    setCurrentLocation(null);
  }

  const getUserCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        setCurrentLocation({
          lat: location.coords.latitude,
          lon: location.coords.longitude,
        });

        onApprovedLocationStatus && onApprovedLocationStatus(GeoLocationPermissionStatus.GRANTED);
        if (!approvedLocationPermission) {
          setApprovedLocationPermission(true);
        }
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            // onError && onError('Location permission denied');
            onApprovedLocationStatus && onApprovedLocationStatus(GeoLocationPermissionStatus.PERMISSION_DENIED);
            setGrantshowLocationError(true);
            break;
          case error.POSITION_UNAVAILABLE:
            onApprovedLocationStatus && onApprovedLocationStatus(GeoLocationPermissionStatus.POSITION_UNAVAILABLE);
            onError && onError('Cannot determine your location');
            break;
          case error.TIMEOUT:
            onApprovedLocationStatus && onApprovedLocationStatus(GeoLocationPermissionStatus.TIMEOUT);
            onError && onError('Timeout while determining your location');
            break;
          default:
            onError && onError(error.message);
        }
      }
    );
  }

  const handleLocationSettings = () => {
    if ((geoLocationPermissionStatus === GeoLocationPermissionStatus.PERMISSION_DENIED)) {
      setGrantshowLocationError(true);
    } else {
      if (approvedLocationPermission) {
        // TODO: use a better logic
        onApprovedLocationStatus && onApprovedLocationStatus(GeoLocationPermissionStatus.UNKNOWN);
        setCurrentLocation(null);
        setApprovedLocationPermission(false);
      } else {
        getUserCurrentLocation();
      }
    }
  };

  useEffect(() => {
    const sortedFloors = sortBy(floorsData, 'sort');
    setFloors(sortedFloors);

    const firstFloor = sortedFloors[0];
    if (firstFloor) {
      setUnitsFilteredByFloor(unitsGroupedByFloor[firstFloor.floorId]);
      setFloorId(firstFloor.floorId);
    }

    const map = EngrainSdk(mapRef.current, apiKey, {
      autoScale: 'geo',
      maxScale: 5.0,
    });

    map.load(props.mapId, () => {
      const levels = map.levels();
      const units = map.units();

      setState({ map, units, levels });
      units.color(unitColor);
    });

    map.on('ready', function () {
      setEngrainMap(map);
      const geo = map.geo();
      const locator = map.locator();
      setMapLocator(locator);
      setMapGeo(geo);

      if (geo.isSupported()) {
        setGeoEnabled(true);
      }
    });
  }, []);

  useEffect(() => {
    if (engrainMap && accessPoints) {
      const levels = engrainMap.levels();
      const deleteMarkers = differenceBy(currentAccessPoints, accessPoints, 'accessPointId');
      const createMarkers = differenceBy(accessPoints, currentAccessPoints, 'accessPointId');
      const currentMarkers = mapMarkers;

      createMarkers.forEach(m => {
        const markersOnLevel = levels.where({ floor: m.externalFloorId }).markers();
        const marker = markersOnLevel.add({
          id: m.accessPointId,
          translate: m.location,
          cursor: 'pointer',
          draggable: false,
          icon: markerIcon ? {
            ...markerIcon
          } : {
            url: MARKER_URL,
            size: [64, 64],
            anchor: [32, 64],
          },
        })[0];

        currentMarkers.push(marker);
      });

      deleteMarkers.forEach(m => {
        const [deleteMarker] = remove(currentMarkers, (marker => marker.id === m.accessPointId));

        if (deleteMarker) {
          deleteMarker.remove();
        }
      });

      if (deleteMarkers.length !== 0 || createMarkers.length !== 0) {
        setAccessPoints(accessPoints);
        setMapMarkers(currentMarkers);
      }
    }
  }, [engrainMap, accessPoints, currentAccessPoints, mapMarkers]);

  useEffect(() => {
    if (mapRef.current.childElementCount > 0) {
      mapRef.current.innerHTML = '';
      resetMap();
    }
  }, [props.mapId]);

  useEffect(() => {
    if (engrainMap && viewMode === EngrainMapViewMode.EDIT) {
      mapRef.current.oncontextmenu = (event) => {
        if (righClickOnMarker(event.target)) {
          return;
        }

        event.preventDefault();

        const viewPort = [event.pageX, event.pageY];
        const point = engrainMap.toLocal(viewPort);
        const locations = { point, viewPort, floorId };

        onMapRightClick && onMapRightClick(locations);
      }
    }
  }, [engrainMap, floorId, viewMode]);

  useEffect(() => {
    if (engrainMap) {
      engrainMap.on('marker:click', (event: MarkerEvent) => {
        onMarkerClick && onMarkerClick(event.marker);
      });

      engrainMap.on('marker:mouseenter', (event: MarkerEvent) => {
        onMarkerMouseEnter && onMarkerMouseEnter(event);
      });

      engrainMap.on('marker:mouseout', (event: MarkerEvent) => {
        onMarkerMouseOut && onMarkerMouseOut(event);
      });

      engrainMap.on('unit:click', (n: UnitEvent) => {
        const unitCoords = engrainMap.units().find(n.unit.id).center();

        if (props.viewMode === EngrainMapViewMode.EDIT) {
          setUnits([n.unit.id]);
        } else {
          const bookedUnitIds = unitDisplayList.map(u => u.unitId);

          if (bookedUnitIds.includes(n.unit.id)) {
            setUnits([n.unit.id]);
          }
        }

        engrainMap.panTo(unitCoords);
        engrainMap.zoomTo(unitCoords, 1);
        return;
      });
    }
  }, [engrainMap, bookedUnits]);

  useEffect(() => {
    if (props.viewMode === EngrainMapViewMode.EDIT) {
      return
    }

    if (mapLocator && currentLocation && mapGeo && geoEnabled) {
      const local = mapGeo.toLocal([currentLocation.lat, currentLocation.lon])
      mapLocator.translate(local).show();
    }

    if (mapLocator && !currentLocation && mapGeo && geoEnabled) {
      mapLocator.hide();
    }
  }, [mapLocator, currentLocation, mapGeo, geoEnabled]);

  useEffect(() => {
    if (mapLocator && zoomToLocation) {
      const location = zoomToLocation.location;
      mapLocator.translate(location).show();
      engrainMap.translate([0,0]);

      setTimeout(() => {
        const halfViewportWidth = window.innerWidth/2;
        const halfViewportHeight = window.innerHeight/2;

        const mapSpaceToScreenSpace = engrainMap.fromLocal(location);
        const xCoordinate = mapSpaceToScreenSpace[0];
        const yCoordinate = mapSpaceToScreenSpace[1];

        engrainMap.panTo([-xCoordinate + halfViewportWidth, -yCoordinate + halfViewportHeight]);
        engrainMap.zoomTo([-xCoordinate + halfViewportWidth, -yCoordinate + halfViewportHeight], 1);
      }, 800);

      if (zoomToLocation.externalFloorId) {
        setFloorId(zoomToLocation.externalFloorId);
      }
    }
  }, [engrainMap, mapLocator, zoomToLocation]);

  useEffect(() => {
    if (geoLocationPermissionStatus === GeoLocationPermissionStatus.GRANTED) {
      setApprovedLocationPermission(true);
    }
  }, [geoLocationPermissionStatus]);

  useEffect(() => {
    if (approvedLocationPermission) {
      getUserCurrentLocation();

      const watchId = navigator.geolocation.watchPosition(
        (location) => {
          setCurrentLocation({
            lat: location.coords.latitude,
            lon: location.coords.longitude,
          });
        },
        (error) => onError && onError(error.message)
      );

      return () => {
        navigator.geolocation.clearWatch(watchId);
      }
    }

    return;
  }, [approvedLocationPermission]);

  const showTutorial = promptTutorial && !finishedTutorial;

  return (
    <div className={styles.mapBody}>
      {!engrainMap && <LoaderPro />}
      {showTutorial && <div className={styles.layer} />}
      <div className={`${styles.mapLoader} ${showTutorial && styles.blur}`} ref={mapRef}></div>
      {enabledLocationFeature && showGrantLocationError && (
        <LocationServicePromp onNext={() => setGrantshowLocationError(false)} />
      )}
      <MapContext.Provider value={state}>
        <div>
          {!!toggleQrScanner &&
            <div className={classNames(styles.qrButtonWrapper, tutorialStep === 0 && styles.zIndex5)}>
              <ButtonIcon
                icon={QrCodeIcon}
                className={
                  classNames(
                    styles.ButtonIconQrCode,
                    showTutorial && tutorialStep === 0 && styles.pulsing,
                    showTutorial && tutorialStep !== 0 && styles.blur,
                  )
                }
                onClick={() => toggleQrScanner()}
                iconDimensions={{ x: '30px', y: '30px' }}
              />
              {showTutorial && tutorialStep === 0 &&
                <>
                  <svg width="80" height="50" viewBox="0 0 80 50" style={{
                    position: 'absolute',
                    top: 62,
                    right: 5,
                    width: 50,
                    zIndex: 2,
                  }}>
                    <polygon points="40,0 80,50 0,50" fill="white" stroke="white" strokeWidth="2" />
                    <path d="M 40 0 L 0 50" stroke="#c7d6e1" strokeWidth="2" />
                    <path d="M 40 0 L 80 50" stroke="#c7d6e1" strokeWidth="2" />
                  </svg>
                  <div className={styles.qrTooltip}>
                    <QRScannerTooltip
                      onNext={() => {
                        if (!geoEnabled) {
                          setFinishedTutorial(true);
                          onFinishedTutorial && onFinishedTutorial();
                        } else {
                          setTutorialStep(tutorialStep + 1)}
                        }
                      }
                    />
                  </div>
                </>
              }
            </div>
          }
          {!!toggleBackButton &&
            <ButtonIcon
              icon={backArrow}
              className={
                classNames(
                  styles.ButtonGoBackwards,
                  showTutorial && styles.blur,
                )
              }
              onClick={() => toggleBackButton()}
              iconDimensions={{ x: '20px', y: '20px' }}
            />
          }
          <div className={styles.bottomController}>
            {enabledLocationFeature &&
              <div className={classNames(styles.locationButtonWrapper, tutorialStep === 1 && styles.zIndex5)}>
                <ButtonIcon
                  icon={
                    approvedLocationPermission
                      ? LocationIconBlue
                      : LocationIconGrayed
                  }
                  className={
                    classNames(
                      showTutorial && tutorialStep === 1 && styles.pulsing,
                      showTutorial && tutorialStep !== 1 && styles.blur,
                    )
                  }
                  onClick={() => handleLocationSettings()}
                  iconDimensions={{ x: '18px', y: '18px' }}
                />
                {showTutorial && tutorialStep === 1 &&
                <>
                  <svg width="80" height="50" viewBox="0 0 80 50" style={{
                    position: 'absolute',
                    bottom: 62,
                    right: 5,
                    zIndex: 2,
                    width: 50,
                  }}>
                    <polygon points="40,50 0,0 80,0" fill="white" stroke="#c7d6e1" strokeWidth="2"></polygon>
                    <path d="M 40 50 L 0 0" stroke="#c7d6e1" strokeWidth="2"></path>
                    <path d="M 40 50 L 80 0" stroke="#c7d6e1" strokeWidth="2"></path>
                  </svg>
                  <div className={styles.locationTooltip}>
                    <LocationTooltip
                      onNext={() => {
                        setTutorialStep(tutorialStep + 1);
                        setFinishedTutorial(true);
                        onFinishedTutorial && onFinishedTutorial();
                      }}
                    />
                  </div>
                </>
              }
              </div>
            }
            <div className={`${showTutorial && styles.blur} ${styles.unitDisplay}`}>
              <UnitDisplay
                units={unitDisplayList}
                onUnitChange={handleUnitChange}
                willHighlightUnit={willHighlightUnit}
              />
            </div>
            <div className={`${showTutorial && styles.blur} ${styles.floorPicker}`}>
              <FloorPicker
                defaultValue={floorId && floorPickerOptions.find(f => f.value === floorId) || floorPickerOptions[0]}
                floors={floorPickerOptions}
                onFloorChange={(floorId) => handleFloorChange(floorId)}
              />
            </div>
          </div>
        </div>
        <Floor floorId={floorId ? floorId : floorsData[0]?.floorId} />
        {unit &&
          <Unit
            color={unitColor}
            unitId={unit.unitId}
            pinCode={unit.pinCode}
            selectedColor={selectedUnitColor}
          />
        }
      </MapContext.Provider>
    </div>
  );
};

export default EngrainMap;
