import { stylesheet, keyframes } from 'typestyle';

const spin = keyframes({
  'from': { transform: 'rotate(0deg)' },
  'to': { transform: 'rotate(360deg)' },
});

const styles = stylesheet({
  Loading: {
    height: '100vh',
    zIndex: 100,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingImg: {
    margin: '20px',
    width: '104px',
    height: '104px',
    animationName: spin,
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  }
});

export default styles;
